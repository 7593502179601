<style lang="less" scoped>
.file-page {
  position: relative;
  &.dragenter::after {
    content: attr(status);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: absolute;
    border: 1px dotted #EEE;
    background-color: rgba(47, 91, 234, .2);
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  nav {
    margin: 10px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #FFF;
    display: flex;
    .back {
      color: #666;
      display: flex;
      line-height: 30px;
      align-items: center;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        color: #2F5BEA;
      }
    }
    ul {
      display: flex;
      li {
        padding: 5px;
        padding-right: 0;
        list-style: none;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          color: #2F5BEA;
        }
        & + li::before {
          cursor: default;
          content: '\e62d';
          transform: rotate(90deg);
          display: inline-block;
          font-family: 'fmico';
          margin: 0 5px;
          color: #EEE;
        }
      }
    }
    ul, li {
      padding: 0;
      margin: 0;
    }
  }
  .folders {
    margin: 10px;
    margin-top: 0;
    .icon {
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
    .item {
      width: 100%;
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .list,  .list li {
      padding: 0;
      margin: 0;
    }
    .list li {
      display: flex;
      align-items: center;
      flex-direction: column;
      list-style: none;
      width: 100px;
      position: relative;
      transition: all .3s;
      user-select: none;
      &:hover {
        background-color: #EEE;
      }
    }
    .list {
      padding: 10px;
      background-color: #FFF;
      display: flex;
      flex-wrap: wrap;
      &:empty::before {
        display: block;
        content: '';
        min-height: 300px;
      }
    }
    .directory, .file {
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="file-page"
    :class="{dragenter: dragenter}"
    @drop="fileDrop"
    @dragenter="onDragenter"
    @dragover="(event) => event.preventDefault()"
    @dragend="onDragend"
  >
    <nav>
      <span class="back" @click="back" v-if="stack.length > 1">
        <i class="fmico fmico-left-arrow"></i>后退
      </span>
      <ul>
        <li v-for="(item, i) in stack" :key="item.title + '' + i" @click="stackChoose(i)">
          <template v-if="label !== null && i === 0">{{label}}</template>
          <template v-else>
            {{item.title && item.title !== 'null' ? item.title : item.data.name}}
          </template>
        </li>
      </ul>
    </nav>
    <div class="folders" folders>
      <ul class="list" folders-list>
        <li folders-item :data-item-index="i" v-for="(item, i) in current" :key="item.title + i" @dblclick="onDbClick(item)" :title="getName(item)">
          <img v-if="item.loading" class="icon" src="/static/images/icon/file/loading.gif" />
          <img v-else class="icon" :src="'/static/images/icon/file/' + getIcon(item)"/>
          <div class="item" :class="{directory: item.data.isDirectory, file: !item.data.isDirectory}">{{getName(item)}}</div>
        </li>
        <li @click="$refs.upload.click()" v-if="funs.upload">
          <img v-if="uploading" class="icon" src="/static/images/icon/file/loading.gif" />
          <i v-else class="iconfont icon-add icon" style="display: flex;align-items: center;justify-content: center;"></i>
          <div class="item file">上传</div>
          <input type="file" style="display: none;" @change="startUpload" ref="upload">
        </li>
      </ul>
    </div>
    <folders-menus ref="menu" />
  </div>
</template>

<script>
import { fileRequest } from '@/api'
import FoldersMenus from './menus'
import { isImg, isText, isPdf, isExcel } from './lib'

export default {
  provide () {
    return {
      folders: this
    }
  },
  props: {
    root: { type: String, default: '/' },
    label: { type: String, default: null },
    uploadCheck: { type: Function },
    funs: {
      type: Object, default: () => {
        return {}
      }
    }
  },
  components: { FoldersMenus },
  data () {
    return {
      dragenter: false,
      stack: [],
      current: [],
      fileTree: [],
      uploading: false
    }
  },
  watch: {
    root: {
      immediate: true,
      async handler (path) {
        if (path) {
          let name = path.split('/').pop() || '/'
          let data = []
          if (this.funs.get) {
            try {
              data = await fileRequest.get({
                path: path
              })
            } catch (error) {
              // await fileRequest.mkdir({
              //   path: path.replace('/' + name, ''),
              //   name: name
              // })
            }
          }
          let root = {
            title: name,
            data: {
              isDirectory: true,
              name: name,
              path: path,
              parentPath: null
            },
            children: []
          }
          if (data.length) {
            root.children = data.map((v) => {
              return {
                title: v.name,
                data: v,
                children: []
              }
            })
          }

          this.stack = [root]
          this.current = root.children
          this.fileTree = root.children
        }
      }
    }
  },
  methods: {
    async startUpload () {
      let files = this.$refs.upload.files
      if (files.length) {
        this.uploading = true
        let ok = await this.$refs.menu.upload(files[0])
        if (ok) {
          await this.$refs.menu.reload()
          this.$refs.upload.value = null
        }
        this.uploading = false
      }
    },
    getName (item) {
      return item.data.name
    },
    getIcon (item) {
      if (item.data.isDirectory) {
        return 'dir.png'
      } else {
        let name = this.getName(item)
        let icon = ['md', 'doc', 'gz', 'sh', 'jar', 'tar', 'exe', 'xls', 'txt', 'zip', 'ppt', 'pdf'].find(v => name.indexOf('.' + v) > -1)
        return icon ? (icon + '.png') : 'file.png'
      }
    },
    back () {
      if (!this.root || this.stack.length > 1) {
        this.stack.pop()
        this.current = this.stack[this.stack.length - 1].children
        // if (this.stack.length) {
        //   this.current = this.stack[this.stack.length - 1].children
        // } else {
        //   this.current = this.root ? [] : [root]
        // }
      }
    },
    stackChoose (i) {
      this.stack.splice(i + 1)
      this.current = this.stack[i].children
    },
    async loadFile (item) {
      if (!this.funs.get) {
        return
      }
      if (item.data.isDirectory) {
        this.$set(item, 'loading', true)
        let data = await fileRequest.get({
          path: item.data.path
        })
        item.children = data.map((v) => {
          return {
            title: v.name,
            data: v,
            children: []
          }
        })
        this.$set(item, 'loading', false)
      }
    },
    loadData () {
      this.fileTree.forEach((item) => {
        this.loadFile(item)
      })
    },
    async onDbClick (data) {
      if (data.children.length === 0) {
        await this.loadFile(data)
      }
      data.children.forEach((item) => {
        if (item.children.length === 0) {
          this.loadFile(item)
        }
      })
      if (data.data.isDirectory) {
        this.current = data.children
        this.stack.push(data)
      } else if (isText(data.data.name)) {
        this.$set(data, 'loading', true)
        this.$refs.menu.item = data
        await this.$refs.menu.editor('view')
        this.$set(data, 'loading', false)
      } else if (isImg(data.data.name)) {
        this.$set(data, 'loading', true)
        await this.$refs.menu.view(data)
        this.$set(data, 'loading', false)
      } else if (isPdf(data.data.name)) {
        this.$set(data, 'loading', true)
        await this.$refs.menu.pdf(data)
        this.$set(data, 'loading', false)
      } else if (isExcel(data.data.name)) {
        this.$set(data, 'loading', true)
        await this.$refs.menu.excel(data)
        this.$set(data, 'loading', false)
      }
    },
    async fileDrop (event) {
      this.$el.setAttribute('status', '上传中...')
      event.preventDefault()
      let files = event.dataTransfer.files;
      if (files.length < 1)
          return;
      await this.$refs.menu.upload(files[0])
      this.$el.setAttribute('status', '上传成功')
      await this.$refs.menu.reload()
      this.dragenter = false
    },
    onDragenter (e) {
      this.$el.setAttribute('status', '释放文件即可上传')
      e.preventDefault()
      this.dragenter = true
    },
    onDragend (e) {
      this.$el.setAttribute('status', '释放文件即可上传')
      e.preventDefault()
      this.dragenter = false
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>