<style scoped lang="less">
  .folders-menus {
    position: fixed !important;
    z-index: 1;
    width: 130px;
    background-color: #FFF;
    box-shadow: 0 0 5px 0 #EEE;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    li {
      padding: 5px 10px;
      color: #657180;
      cursor: pointer;
      list-style: none;
      transition: all .3s;
      &.disabled, &.disabled:hover {color: #EEE;cursor: not-allowed;}
      &:hover {
        color: #2F5BEA;
      }
    }
  }
</style>

<style lang="less">
  .fm-modal-body {
    .editormd {
      border: none;
    }
    .editormd-html-preview, .editormd-preview-container {
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>

<template>
  <div>
    <ul class="folders-menus" :style="styles" v-if="mode" v-loadingx="loading">
      <li class="folders-menu-item" @click="action('mkdir')" v-if="newDir">新增文件夹</li>
      <!-- <li class="folders-menu-item" @click="action('touch')" v-if="newFile">新增文件</li> -->
      <li class="folders-menu-item" @click="action('view')" v-if="isViewer">查看</li>
      <li class="folders-menu-item" @click="action('down')" v-if="isDown">下载</li>
      <!-- <li class="folders-menu-item" @click="action('edit')" v-if="isEditor">编辑</li> -->
      <li class="folders-menu-item" @click="action('del')" v-if="isDel">删除</li>
      <li class="folders-menu-item" @click="action('reload')" v-if="isReload">刷新</li>
      <!-- <li class="folders-menu-item disabled">重命名</li> -->
    </ul>
    <!-- <fm-modal :mask-closable="false" :value="editorType !== null" width="1000px" @cancel="closeEditor">
      <div slot="header" v-if="editData">{{editData.title && editData.title !== 'null' ? editData.title : editData.data.name}} - [{{editorType === 'edit' ? '编辑' : '查看'}}]</div>
      <div style="width: 100%;height: 500px;position: relative;">
        <editor v-model="content" v-if="editorType !== null" :key="editorType" :type="editorType" />
      </div>
      <div slot="footer" class="modal-footer-btns">
        <fm-btn v-if="editorType === 'edit'" @click="save" type="primary">保存</fm-btn>
        <fm-btn v-else @click="editorType = 'edit'" type="primary">编辑</fm-btn>
        <fm-btn @click="closeEditor">关闭</fm-btn>
      </div>
    </fm-modal> -->

    <ImgPreview :show="viewer.preview" :index="viewer.index" :imgs="viewer.imgs" @close="viewer.preview = false"></ImgPreview>
    
    <fm-modal v-model="viewer.show" width="1000px" @cancel="viewerClose">
      <img :src="viewer.src" v-if="viewer.type === 'img'" style="width: 100%"/>
      <iframe :src="viewer.src" v-if="viewer.type === 'pdf'" frameborder="0" style="width: 100%;height: 70vh;"></iframe>
      <div v-if="viewer.type === 'excel'">
        <ul style="display: flex;">
          <li style="cursor: pointer;" @click="excelViewer(sheetName)" v-for="(sheetName, i) in viewer.workbook.SheetNames" :key="sheetName + i">{{sheetName}}</li>
        </ul>
        <iframe ref="excelViewer" frameborder="0" style="width: 100%;height: 70vh;"></iframe>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import { isImg, isPdf, isText, isExcel } from './lib'
import XLSX from 'xlsx'

function getSheetHtml (sheet) {
  let style = "<style>table{border-collapse: collapse;}td {border: 1px solid #EEE;padding: 5px 8px;}</style>"
  return style + XLSX.utils.sheet_to_html(sheet)
}

import {
  fileRequest
} from '@/api'

import {
  fileHepler
} from '@/fmlib'

import ImgPreview from './preview/img'

export default {
  components: { ImgPreview },
  data () {
    return {
      loading: false,
      viewer: {
        preview: false,
        imgs: [],
        index: null,
        show: false,
        type: null,
        src: null,
        workbook: {}
      },
      mode: null,
      item: null,
      position: {
        x: 0,
        y: 0
      },
      content: null,
      editorType: null,
      editData: null,
      src: null
    }
  },
  inject: ['folders'],
  computed: {
    isReload () {
      return this.folders.funs.get && true
    },
    isViewer () {
      return this.folders.funs.get && this.mode === 'item' && this.item && !this.item.data.isDirectory && (isImg(this.item.data.name) || isPdf(this.item.data.name) || isExcel(this.item.data.name))
    },
    isDel () {
      return this.folders.funs.del && this.mode === 'item'
    },
    isDown () {
      return this.folders.funs.down && this.mode === 'item' && this.item && !this.item.data.isDirectory
    },
    newDir () {
      return this.folders.funs.mkdir && ['folders', 'list'].includes(this.mode)
    },
    newFile () {
      return this.folders.funs.add && ['folders', 'list'].includes(this.mode)
    },
    styles () {
      return {
        left: this.position.x + 'px',
        top: this.position.y + 'px'
      }
    }
  },
  methods: {
    closeEditor () {
      this.editorType = null
      this.editData = null
      this.content = null
    },
    async save () {
      try {
        this.loading = true
        const file = new File([this.content], this.editData.data.name)
        const dir = this.getDir()
        if (!dir) {
          throw new Error('目录错误')
        }
        let parm = new FormData()
        parm.append('file', file)
        parm.append('path', dir.data.path)
        const path = await fileRequest.upload(parm)
        this.loading = false
        this.closeEditor()
        this.$notice.success('文件已保存: ' + path)
      } catch (error) {
        this.loading = false
        this.$notice.error(error.message)
      }
    },
    close () {
      this.mode = null
      this.item = null
    },
    async action (key) {
      switch (key) {
        case 'del':
          await this.delData()
          break;
        case 'mkdir':
          await this.mkdir()
          break;
        case 'down':
          await this.down()
          break;
        case 'touch':
          await this.touch()
          break;
        case 'reload':
          await this.reload()
          break;
        case 'edit':
        case 'view':
          if (isImg(this.item.data.name)) {
            await this.view(this.item)
          } else if (isText(this.item.data.name)) {
            await this.editor(key)
          } else if (isPdf(this.item.data.name)) {
            await this.pdf(this.item)
          } else if (isExcel(this.item.data.name)) {
            await this.excel(this.item)
          } else {
            this.$notice.info('暂不支持查看')
          }
          break;
      }
      this.close()
    },
    onContextmenu (e) {
      this.position.x = e.clientX
      this.position.y = e.clientY
      e.preventDefault()
      e.stopPropagation()
      this.item = null
      if (e.target.hasAttribute('folders')) {
        this.foldersChose(e.target)
      } else if (e.target.parentElement.hasAttribute('folders')) {
        this.foldersChose(e.target.parentElement)
      } else if (e.target.hasAttribute('folders-list')) {
        this.listChose(e.target)
      } else if (e.target.parentElement.hasAttribute('folders-list')) {
        this.listChose(e.target.parentElement)
      } else if (e.target.hasAttribute('folders-item')) {
        this.itemChose(e.target)
      } else if (e.target.parentElement.hasAttribute('folders-item')) {
        this.itemChose(e.target.parentElement)
      }
    },
    getDir () {
        return this.folders.stack.length ? this.folders.stack[this.folders.stack.length - 1] : null
    },
    foldersChose () {
      let dir = this.getDir()
      if (dir) {
        this.item = dir
        this.mode = 'folders'
      }
    },
    listChose () {
      let dir = this.getDir()
      if (dir) {
        this.item = dir
        this.mode = 'list'
      }
    },
    itemChose (target) {
      this.item = this.folders.current[target.dataset.itemIndex] ? this.folders.current[target.dataset.itemIndex] : null
      if (this.item) {
        this.mode = 'item'
      }
    },
    outsideClick (e) {
      if (!e.target.classList.contains('folders-menu-item')) {
        this.close()
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除文件 ' + this.item.data.name + ' 吗?'})
      if (result) {
        await fileRequest.del({'fullPath': this.item.data.path})
        let index = this.folders.current.findIndex(v => v.data.path === this.item.data.path)
        index > -1 && this.folders.current.splice(index, 1)
        this.$notice.info({
          title: '系统提示',
          desc: '文件已删除'
        })
        this.folders.$emit('fileUpdate', 'del')
      }
    },
    async mkdir () {
      const name = await this.$dialog.prompt({
        title: '新建文件夹',
        promptValue: '',
        placeholder: '请输入文件夹名称'
      })
      if (!name) {
        return false
      }
      let parm = {
        path: this.item.data.path,
        name: name
      }
      await fileRequest.mkdir(parm)
      this.folders.current.push({
        title: parm.name,
        children: [],
        data: {
          isDirectory: true,
          name: parm.name,
          parentPath: parm.path,
          path: parm.path + '/' + parm.name,
        }
      })
    },
    async touch () {
      const name = await this.$dialog.prompt({
        title: '新建文件',
        promptValue: '',
        placeholder: '请输入文件名称'
      })
      if (!name) {
        return false
      }
      try {
        this.loading = true
        if (['.md', '.txt'].findIndex(v => name && new RegExp(v, 'i').test(name)) === -1) {
          throw new Error('仅允许创建md和txt的文件')
        }
        this.upload(new File([], name))
        this.loading = false
        this.reload()
      } catch (error) {
        this.loading = false
        this.$notice.error(error.message)
      }
    },
    async upload (file) {
      if (this.folders.uploadCheck) {
        let ok = this.folders.uploadCheck({
          fileType: this.folders.isImg(file.name) ? 'img' : 'other',
          file: file,
          dirFiles: this.folders.current
        })
        if (!ok) {
          return false
        }
      }
      const dir = this.getDir()
      if (!dir) {
        this.$notice.error('当前目录不可操作')
        return false
      }
      let parm = new FormData()
      parm.append('file', file)
      parm.append('path', dir.data.path)
      // const path = await fileRequest.upload(parm)
      await fileRequest.upload(parm)
      this.$notice.success('文件上传成功: ' + file.name)
      this.folders.$emit('fileUpdate', 'upload')
      return true
    },
    async down () {
      if (!this.item.data.isDirectory) {
        const fileData = await fileRequest.download({
          path: this.item.data.path
        })
        fileHepler.downFile(fileData, this.item.data.name)
      }
    },
    async editor (type) {
      if (!this.item.data.isDirectory) {
        this.loading = true
        const fileData = await fileRequest.download({
          path: this.item.data.path
        })
        this.content = await new Promise(resolve => {
          let reader = new FileReader()
          reader.readAsText(fileData)
          reader.onload = () => resolve(reader.result)
        })
        this.editData = this.item
        this.editorType = type
        this.loading = false
      }
    },
    async view (item) {
      const dir = this.getDir()
      const index = dir.children.findIndex(v => v.data.path === item.data.path)

      this.viewer.preview = true
      this.viewer.imgs = dir.children.map(v => v.data.path)
      this.viewer.index = index

      // this.loading = true
      // const fileData = await fileRequest.download({
      //   path: item.data.path
      // })
      // const src = await new Promise(resolve => {
      //   let reader = new FileReader()
      //   reader.readAsDataURL(fileData)
      //   reader.onload = () => resolve(reader.result)
      // })
      // this.viewer.src = src
      // this.viewer.type = 'img'
      // this.viewer.show = true
      // this.loading = false
    },
    async pdf (item) {
      this.loading = true
      const res = await fileRequest.download({ path: item.data.path })
      this.viewer.src = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf '}))
      this.viewer.type = 'pdf'
      this.viewer.show = true
      this.loading = false
    },
    async excel (item) {
      this.loading = true
      const res = await fileRequest.download({ path: item.data.path })
      const buffer = await res.arrayBuffer()
      this.viewer.workbook = XLSX.read(buffer, {type: 'array'})
      this.viewer.type = 'excel'
      this.viewer.show = true
      this.$nextTick(this.excelViewer)
      this.loading = false
    },
    viewerClose () {
      this.viewer.show = false
      this.viewer.type = null
      this.viewer.src = null
      this.viewer.workbook = null
    },
    excelViewer (sheetName) {
      this.$refs.excelViewer.contentDocument.querySelector('body').innerHTML = getSheetHtml(this.viewer.workbook.Sheets[sheetName || this.viewer.workbook.SheetNames[0]])
    },
    async reload () {
      await this.folders.loadFile(this.getDir())
      this.folders.current = this.getDir().children
    }
  },
  mounted () {
    document.addEventListener('contextmenu', this.onContextmenu)
    document.addEventListener('click', this.outsideClick)
  },
  destroyed () {
    document.removeEventListener('contextmenu', this.onContextmenu) 
    document.removeEventListener('click', this.outsideClick) 
  }
}
</script>
