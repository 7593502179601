import sysEnv from '@/env/env'
import { XDate } from '@/components/fmui/components/FmDatePicker/lib'
import { getBirthByIdNo, getAge } from '@/syslib/tools'

function dateFormat (tim, str, defaultVal = '') {
  if (tim) {
    return new XDate(tim).format(str)
  } else {
    return defaultVal
  }
}

const isIDNo = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/

function getConfig (env) {
  if (env === 'hrms_thjkzx') {
    return {
      importModes: [
        { label: '数据导入', value: 'default', checked: true },
        { label: '结果导入', value: 'result' },
        { label: '系统导入', value: 'sys' }
      ],
      transData (list, mode) {
        console.log('trans', list)
        const serialization = this.getExcelSerialization(mode)
        
        const titleIndex = ['default', 'result'].includes(mode) ? 1 : 0
        const row = list.slice(titleIndex, titleIndex + 1).shift()

        const keyMap = Object.keys(row).map(key => {
          const col = serialization.find(v => {
            if (Array.isArray(v.label)) {
              return v.label.includes(String(row[key]).trim()) || v.label.includes(String(key).trim())
            } else {
              return v.label === String(row[key]).trim() || v.label === String(key).trim()
            }
          })
          return col ? {
            key: col.key,
            field: key
          } : null
        }).filter(v => v)

        console.log(titleIndex, row, keyMap)

        if (keyMap.length !== serialization.length) {
          const lose = serialization.filter(s => {
            return keyMap.findIndex(v => v.key === s.key) < 0
          }).map(v => Array.isArray(v.label) ? v.label[0] : v.label)
          throw new Error('模版格式不匹配，无法导入，缺少字段：【' + lose.join('、') + '】，请检查标题名称是否填错或者缺少列。')
        }

        return list.slice(titleIndex + 1).map(row => {
          let obj = {}
          keyMap.forEach(({key, field}) => {
            obj[key] = row[field] ? String(row[field]).trim() : null
          })
          return obj
        }).filter(v => ['default', 'result'].includes(mode) ? (v.name && v.no) : (v.name && v.idno)).map(row => {
          row.source = mode === 'sys' ? 'sys' : 'default'
          if (mode === 'sys') {
            try {
              row.age = getAge(getBirthByIdNo(row.idno))
            } catch (error) {
              row.age = null
            }
            row.gender = row.idno && isIDNo.test(row.idno) ? (row.idno.substr(16, 1) % 2 === 1 ? '男' : '女') : null
            row.rylb = null
            row.address = null
            row.yblx = '咽拭子'
            row.cydd = null
            row.cyr = null
            row.remark = null
            row.cyrq = row.jcsj ? new XDate(row.jcsj).format('Y-M-D H:I:S') : null
            row.jcsj = row.jcsj ? new XDate(row.jcsj).format('Y-M-D H:I:S') : null
            row.jsrq = row.jcsj ? new XDate(row.jcsj).format('Y-M-D H:I:S') : null
            row.bgsj = row.jcsj ? new XDate(row.jcsj).format('Y-M-D H:I:S') : null
            row.syrq = row.jcsj ? new XDate(row.jcsj).format('Y-M-D H:I:S') : null
          } else {
            if (!row.age) {
              try {
                row.age = getAge(getBirthByIdNo(row.idno))
              } catch (error) {
                row.age = null
              }
            }

            if (!row.gender) {
              row.gender = row.idno && isIDNo.test(row.idno) ? (row.idno.substr(16, 1) % 2 === 1 ? '男' : '女') : null
            }

            if (mode !== 'result') {
              delete row.result
            }

            row.syrq = row.syrq ? new XDate(row.syrq).format('Y-M-D H:I:S') : null
            row.cyrq = row.cyrq ? new XDate(row.cyrq).format('Y-M-D H:I:S') : null
            row.jcsj = row.jcsj ? new XDate(row.jcsj).format('Y-M-D H:I:S') : null
            row.jsrq = row.jsrq ? new XDate(row.jsrq).format('Y-M-D H:I:S') : null
            row.bgsj = row.bgsj ? new XDate(row.bgsj).format('Y-M-D H:I:S') : null
          }

          row.shr = '马明'

          return row
        })
      },
      renderItem (item, signature) {
        const json = item.jsonStr ? JSON.parse(item.jsonStr) : {}
        const checkMan = signature.find(v => v.name === item.jyry) || {}
        const yz = signature.find(v => v.name === '印章') || {}
        const shr = signature.find(v => v.name === item.shr) || {}

        const cyrq = item.cyrq
        const jsrq = item.jsrq ? item.jsrq : item.cyrq
        const jcsj = item.jcsj ? item.jcsj : item.cyrq
        const bgsj = item.bgsj ? item.bgsj : item.jcsj
        return '<div class="nucleic-item-print">'
          + '<table>'
            + '<thead class="bt">'
              + '<th colspan="6" class="center lh50 f24 weight500">太湖县疾病预防控制中心检验报告单</th>'
              + '<tr>'
                + '<th colspan="3" class="left weight500 lh35">送检单位：' + (item.cydd || '') + '</th>'
                + '<th colspan="3" class="right weight500 lh35">样本编号：' + (item.code || item.ybid || '') + '</th>'
              + '</tr>'
            + '</thead>'
            + '<tr>'
              + '<td>姓名：' + (item.name || '') + '</td>'
              + '<td>科室：</td>'
              + '<td colspan="2">送检医生：' + (item.cyr || '') + '</td>'
              + '<td colspan="2">人员类别：' + (item.rylb || '') + '</td>'
            + '</tr>'
            + '<tr>'
              + '<td>性别：' + (item.gender || '') + '</td>'
              + '<td>电话：</td>'
              + '<td colspan="2">医生电话：</td>'
              + '<td colspan="2">样本类型：' + (json.yblx || '咽拭子') + '</td>'
            + '</tr>'
            + '<tr>'
              + '<td>年龄：' + (item.age || '') + '</td>'
              + '<td>身份证号：</td>'
              + '<td colspan="3">' + (item.idno || '') + '</td>'
              + '<td>样本状态：外观正常</td>'
            + '</tr>'
          + '</table>'
            + '<div class="row" style="word-break: keep-all;white-space: nowrap;">'
              + '<img src="/static/images/nucleic/title-col.png" class="bg" />'
              + '<div class="col" style="flex: 2;">'
                + '<div class="lh35 weight500 center f14">项目名称</div>'
                + '<div class="plr5">新型冠状病毒N基因</div>'
                + '<div class="plr5">新型冠状病毒ORF1ab基因</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 weight500 center f14">项目简称</div>'
                + '<div class="plr5">2019-nCoV N</div>'
                + '<div class="plr5">2019-nCoV ORF1ab</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 weight500 center f14">结果</div>'
                + '<div class="plr5 center">' + (item.result || '') + '</div>'
                + '<div class="plr5 center">' + (item.result || '') + '</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 weight500 center f14">单位</div>'
                + '<div></div>'
                + '<div></div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 weight500 center f14">参考区间</div>'
                + '<div class="plr5 center">阴性</div>'
                + '<div class="plr5 center">阴性</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 weight500 center f14">方法学</div>'
                + '<div class="plr5">PCR荧光探针法</div>'
                + '<div class="plr5">PCR荧光探针法</div>'
              + '</div>'
            + '</div>'
          + '<table>'
            + '<tr class="bt f14">'
              + '<td colspan="6" style="padding-top: 60px">检测结果仅适用于此次采集样本，单次核酸检测阴性不能完全排除新型冠状病毒感染。</td>'
            + '</tr>'
            + '<tr class="f14">'
              + '<td>采样时间：' + dateFormat(cyrq, 'Y-M-D', '') + '</td>'
              + '<td colspan="2">接收时间：' + dateFormat(jsrq, 'Y-M-D', '') + '</td>'
              + '<td colspan="2">检验时间：' + dateFormat(jcsj, 'Y-M-D H:I', '') + '</td>'
              + '<td>报告时间：' + dateFormat(bgsj, 'Y-M-D', '') + '</td>'
            + '</tr>'
            + '<tr class="lh40 f14">'
              + '<td colspan="3"><span style="position: relative;top:-12px;">检验人员：</span><img src="' + (checkMan.src || '') + '" class="sign" /></td>'
              + '<td colspan="3" class="left relative"><span style="position: relative;top:-12px;">审核人员：</span><img src="' + (shr.src || '') + '" class="sign" /><img style="opacity: 0.65;" src="' + (yz.src || '') + '" class="chapter" /></td>'
            + '</tr>'
          + '</table>'
        + '</div>'
      },
      getSignData () {
        return [
          { name: '吴张亮', path: '/signature/5.png', src: null },
          { name: '徐书硕', path: '/signature/14.png', src: null },
          { name: '吴姗姗', path: '/signature/7.png', src: null },
          { name: '刘宋兵', path: '/signature/8.png', src: null },
          { name: '胡英子', path: '/signature/9.png', src: null },
          { name: '宋林红', path: '/signature/10.png', src: null },
          { name: '张若楠', path: '/signature/11.png', src: null },
          { name: '马明', path: '/signature/12.png', src: null },
          { name: '印章', path: '/signature/13.png', src: null }
        ]
      },
      getExcelSerialization (mode) {
        return mode === 'sys' ? [
          { key: 'name', label: '姓名' },
          { key: 'idno', label: '证件号码' },
          { key: 'result', label: ['检测结果（阳性/阴性）', '核酸检测结果（阳性/阴性）'] },
          { key: 'jcsj', label: '核酸检测时间' },
          { key: 'jcjg', label: '核酸检测机构' }
        ] : [
          { key: 'no', label: '序号' },
          { key: 'ybid', label: '样品编号' },
          { key: 'name', label: ['姓名', '患者姓名'] },
          { key: 'rylb', label: '人员类别' },
          { key: 'gender', label: '性别' },
          { key: 'age', label: '年龄' },
          { key: 'idno', label: '身份证号码' },
          { key: 'address', label: ['地址', '住址'] },
          { key: 'yblx', label: '样本类型' },
          { key: 'cydd', label: '采样单位' },
          { key: 'cyrq', label: '采样日期' },
          { key: 'syrq', label: '送样日期' },
          { key: 'cyr', label: '采样人' },
          { key: 'jcjg', label: '核酸检测机构名称' },
          { key: 'remark', label: '备注' },
          { key: 'result', label: '结果' }
        ]
      },
      getDefault () {
        let obj = {}
        this.getFormParams().forEach(v => obj[v.key] = v.default)
        return obj
      },
      getFormParams () {
        return [
          { key: 'ybid', group: 'field', label: '样本编号', type: 'input', default: null },
          { key: 'name', group: 'field', label: '姓名', type: 'input', default: null },
          { key: 'rylb', group: 'field', label: '人员类别', type: 'input', default: null },
          {
            key: 'gender', group: 'field', label: '性别', type: 'radio', default: '男', radios: [
              { label: '男', value: '男' },
              { label: '女', value: '女' }
            ]
          },
          { key: 'age', group: 'field', label: '年龄', type: 'number', default: null, min: 0, max: 128 },
          { key: 'idno', group: 'field', label: '身份证号', type: 'input', default: null },
          { key: 'address', group: 'field', label: '地址', type: 'input', default: null },
          { key: 'yblx', group: 'json', label: '样本类型', type: 'input', default: null },
          { key: 'cydd', group: 'field', label: '采样单位', type: 'input', default: null },
          { key: 'cyrq', group: 'field', label: '采样日期', type: 'date', default: new Date() },
          { key: 'syrq', group: 'field', label: '送样日期', type: 'date', default: new Date() },
          { key: 'jcsj', group: 'field', label: '检测时间', type: 'datetime', default: new Date(), },
          { key: 'jsrq', group: 'field', label: '接收时间', type: 'date', default: new Date(), },
          { key: 'bgsj', group: 'field', label: '报告时间', type: 'date', default: new Date(), },
          { key: 'jcjg', group: 'field', label: '检测机构', type: 'input', default: null },
          { key: 'cyr', group: 'field', label: '采样人', type: 'input', default: null },
          {
            key: 'jyry', group: 'field', label: '检验人员', type: 'select', default: null, options: this.getSignData().filter(v => v.name !== '印章').map(v => {
              return { label: v.name, value: v.name }
            })
          },
          {
            key: 'shr', group: 'field', label: '审核人', type: 'select', default: null, options: this.getSignData().filter(v => v.name !== '印章').map(v => {
              return { label: v.name, value: v.name }
            })
          },
          { key: 'remark', group: 'json', label: '备注', type: 'input', default: null },
          {
            key: 'result', group: 'field', label: '结果', type: 'radio', default: null, radios: [
              { label: '阳性', value: '阳性' },
              { label: '阴性', value: '阴性' }
            ]
          }
        ]
      },
      getOverlayDefault (mode) {
        let obj = {}
        this.getOverlayFormParams(mode).forEach(v => obj[v.key] = v.default)
        return obj
      },
      getOverlayFormParams (mode) {
        const item = {
          key: 'jyry', label: '检验人员', type: 'select', default: null, options: this.getSignData().filter(v => v.name !== '印章').map(v => {
            return { label: v.name, value: v.name }
          })
        }
        if (mode === 'sys') {
          return [
            {
              key: 'cydd', label: '送检单位', type: 'select', default: '系统采集点', options: [
                { label: '高速路口采样点', value: '高速路口采样点' },
                { label: '火车站采样点', value: '火车站采样点' },
                { label: '高铁南站采样点', value: '高铁南站采样点' },
                { label: '太湖县疾控中心', value: '太湖县疾控中心' },
                { label: '系统采集点', value: '系统采集点' },
                { label: '博爱医院', value: '博爱医院' },
                { label: '达圣体检中心', value: '达圣体检中心' },
                { label: '中山医院', value: '中山医院' },
                { label: '微创医院', value: '微创医院' },
                { label: '太湖健康口腔', value: '太湖健康口腔' }
              ],
            },
            { key: 'rylb', label: '人员类别', type: 'input', default: '15' },
            item
          ]
        } else if (mode === 'result') {
          return [ item ]
        } else {
          return []
        }
      },
      getUpdateFormParams () {
        return [
          {
            key: 'jyry', label: '检验人员', type: 'select', required: true, default: null, options: this.getSignData().filter(v => v.name !== '印章').map(v => {
              return { label: v.name, value: v.name }
            })
          },
          {
            key: 'jcsj', label: '检测时间', type: 'datetime', required: true, default: new Date(), format (v) {
              return dateFormat(v, 'Y-M-D H:I:S')
            }
          }
        ]
      },
      getUpdateDefault () {
        let obj = {}
        this.getUpdateFormParams().forEach(v => obj[v.key] = v.default)
        return obj
      },
      getDatas (list) {
        const params = this.getFormParams()
        return list.map(item => {
          let json = {}
          let data = { source: item.source || 'default' }
          params.forEach(v => {
            if (item[v.key] === undefined) {
              item[v.key] = null
            }

            if (v.group === 'json') {
              json[v.key] = item[v.key]
            } else {
              data[v.key] = item[v.key]
            }
          })
          data.jsonStr = JSON.stringify(json)
          return data
        })
      },
      checkOverlay (data, mode) {
        if (mode === 'sys' && !data.cydd) {
          return '送检单位不能为空'
        }

        if ((mode === 'sys' || mode === 'result') && !data.jyry) {
          return '检验人员不能为空'
        }

        return true
      },
      checkUpdate (dataList, mode) {
        if (mode === 'sys') {
          return {
            list: dataList.filter(v => !v.jcsj || !v.name || !v.cydd),
            msg: '检测时间、姓名、采样单位不能为空，请检查'
          }
        } else if (mode === 'result') {
          return {
            list: dataList.filter(v => !v.ybid || !v.cyrq || !v.name || !v.cydd || !v.result),
            msg: '样本编号、采样日期、姓名、采样单位、检测结果不能为空，请检查'
          }
        } else {
          return {
            list: dataList.filter(v => !v.ybid || !v.cyrq || !v.name || !v.cydd),
            msg: '样本编号、采样日期、姓名、采样单位不能为空，请检查'
          }
        }
      },
      checkFormData (data) {
        if (!data.name) {
          throw new Error('姓名不能为空')
        }

        if (!data.idno) {
          throw new Error('身份证不能为空')
        }

        // if (!data.jcsj) {
        //   throw new Error('检测时间不能为空')
        // }

        if (!data.cyrq) {
          throw new Error('采样日期不能为空')
        }
  
        if (!data.cydd) {
          throw new Error('采样单位不能为空')
        }
      }
    }
  } else {
    return {
      importModes: [
        { label: '普通导入', value: 'default', checked: true }
      ],
      transData (list, mode) {
        const keyMap = this.getExcelSerialization(mode)
        return list.slice(0).map(row => {
          let obj = {}
          Object.keys(row).map((key, i) => {
            obj[keyMap[i]] = row[key]
          })
          return obj
        }).filter(v => v.ybid && v.jcsj).map(row => {
          row.source = 'default'

          if (!row.age) {
            try {
              row.age = getAge(getBirthByIdNo(row.idno))
            } catch (error) {
              row.age = null
            }
          }

          if (!row.gender) {
            row.gender = row.idno ? (row.idno.substr(16, 1) % 2 === 1 ? '男' : '女') : null
          }

          row.cyrq = row.cyrq ? new XDate(row.cyrq).format('Y-M-D') : null
          row.jcsj = row.jcsj ? new XDate(row.jcsj).format('Y-M-D H:I:S') : null
          row.jsrq = row.jsrq ? new XDate(row.jsrq).format('Y-M-D') : new XDate(row.jcsj).format('Y-M-D')
          row.syrq = row.jsrq
          row.bgsj = row.bgsj ? new XDate(row.bgsj).format('Y-M-D H:I') : new XDate(row.jcsj).format('Y-M-D H:I')
          return row
        })
      },
      renderItem (item, signature) {
        const yz = signature.find(v => v.name === '印章') || {}
        const lhs = signature.find(v => v.name === '李术华') || {}
        const cyr = signature.find(v => v.name === (item.cyr || '')) || {}
        // const types = { 1: '发热病人', 2: '住院病人', 3: '陪护人员', 4: '普通门急诊', 5: '新增密切接触者及重要关系人', 6: '中高风险地区归来未满14天人员（需备注具体地址）', 7: '外省务工返乡人员（需备注具体地址）', 8: '境外来返县人员', 9: '特殊场所备勤返岗及新收监、新入住人员（需备注）', 10: '医疗机构工作人员', 11: '市场从业人员', 12: '快递人员', 13: '外卖人员', 14: '自愿检测人员', 15: '其他人员（需备注）'}
        return '<div class="nucleic-item-print">'
          + '<table>'
            + '<thead class="bt">'
              + '<th colspan="6" class="center lh50 f24 bold">太湖县妇幼保健院检验报告单</th>'
              + '<tr>'
                + '<th colspan="3" class="left bold lh35">送检单位：' + (item.cydd || '') + '</th>'
                + '<th colspan="3" class="right bold lh35">样本编号：' + (item.ybid || '') + '</th>'
              + '</tr>'
            + '</thead>'
            + '<tr>'
              + '<td>姓名：' + (item.name || '') + '</td>'
              + '<td>科室：</td>'
              + '<td colspan="2">送检医生：' + (item.cyr || '') + '</td>'
              + '<td colspan="2">人员类别：</td>'
            + '</tr>'
            + '<tr>'
              + '<td>性别：' + (item.gender || '') + '</td>'
              + '<td>电话：</td>'
              + '<td colspan="2">医生电话：</td>'
              + '<td colspan="2">样本类型：咽拭子</td>'
            + '</tr>'
            + '<tr>'
              + '<td>年龄：' + (item.age || '') + '</td>'
              + '<td>身份证号：</td>'
              + '<td colspan="3">' + (item.idno || '') + '</td>'
              + '<td>样本状态：外观正常</td>'
            + '</tr>'
          + '</table>'
            + '<div class="row" style="word-break: keep-all;white-space: nowrap;">'
              + '<img src="/static/images/nucleic/title-col.png" class="bg" />'
              + '<div class="col" style="flex: 2;">'
                + '<div class="lh35 bold center">项目名称</div>'
                + '<div class="plr5">新型冠状病毒N基因</div>'
                + '<div class="plr5">新型冠状病毒ORF1ab基因</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 bold center">项目简称</div>'
                + '<div class="plr5">2019-nCoV N</div>'
                + '<div class="plr5">2019-nCoV ORF1ab</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 bold center">结果</div>'
                + '<div class="plr5 center">' + (item.result || '') + '</div>'
                + '<div class="plr5 center">' + (item.result || '') + '</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 bold center">单位</div>'
                + '<div></div>'
                + '<div></div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 bold center">参考区间</div>'
                + '<div class="plr5 center">阴性</div>'
                + '<div class="plr5 center">阴性</div>'
              + '</div>'
              + '<div class="col">'
                + '<div class="lh35 bold center">方法学</div>'
                + '<div class="plr5">PCR荧光探针法</div>'
                + '<div class="plr5">PCR荧光探针法</div>'
              + '</div>'
            + '</div>'
          + '<table>'
            + '<tr class="bt f14">'
              + '<td colspan="6" style="padding-top: 60px">检测结果仅适用于此次采集样本，单次核酸检测阴性不能完全排除新型冠状病毒感染。</td>'
            + '</tr>'
            + '<tr class="f14">'
              + '<td>采样时间：' + dateFormat(item.cyrq, 'Y-M-D', '') + '</td>'
              + '<td colspan="2">接收时间：' + dateFormat(item.jsrq, 'Y-M-D', '') + '</td>'
              + '<td colspan="2">检验时间：' + dateFormat(item.jcsj, 'Y-M-D', '') + '</td>'
              + '<td>报告时间：' + dateFormat(item.bgsj, 'Y-M-D', '') + '</td>'
            + '</tr>'
            + '<tr class="lh40 f14">'
              + '<td colspan="3"><span style="position: relative;top:-12px;">检验人员：</span><img src="' + (cyr.src || '') + '" class="sign" /></td>'
              + '<td colspan="3" class="left relative"><span style="position: relative;top:-12px;">审核人员：</span><img src="' + (lhs.src || '') + '" class="sign" /><img style="opacity: 0.65;" src="' + (yz.src || '') + '" class="chapter" /></td>'
            + '</tr>'
          + '</table>'
        + '</div>'
      },
      getSignData () {
        return [
          { name: '朱丹妮', path: '/signature/4786.png', src: null },
          { name: '印章', path: '/signature/4787.png', src: null },
          { name: '李术华', path: '/signature/4788.png', src: null },
          { name: '陈林琼', path: '/signature/4789.png', src: null }
        ]
      },
      getExcelSerialization () {
        return ['name', 'idno', 'result', 'jcsj', 'jcjg', 'ybid', 'rylb', 'gender', 'age', 'address', 'cydd', 'cyrq', 'cyr', 'id']
      },
      getDefault () {
        let obj = {}
        this.getFormParams().forEach(v => obj[v.key] = v.default)
        return obj
      },
      getFormParams () {
        return [
          { key: 'name', label: '年龄', type: 'input', default: null, },
          { key: 'idno', label: '身份证号', type: 'input', default: null, },
          {
            key: 'result', label: '结果', type: 'radio', default: '阴性', radios: [
              { label: '阳性', value: '阳性' },
              { label: '阴性', value: '阴性' }
            ]
          },
          { key: 'cyrq', label: '采样日期', type: 'date', default: new Date(), },
          { key: 'syrq', group: 'field', label: '送样日期', type: 'date', default: new Date() },
          { key: 'jsrq', label: '接收时间', type: 'date', default: new Date(), },
          { key: 'jcsj', label: '检测时间', type: 'date', default: new Date(), },
          { key: 'bgsj', label: '报告时间', type: 'date', default: new Date(), },
          { key: 'jcjg', label: '检测机构', type: 'input', default: null, },
          { key: 'ybid', label: '样本编号', type: 'input', default: null, },
          { key: 'rylb', label: '人员类别', type: 'input', default: null, },
          {
            key: 'gender', label: '性别', type: 'radio', default: '男', radios: [
              { label: '男', value: '男' },
              { label: '女', value: '女' }
            ]
          },
          { key: 'age', label: '年龄', type: 'number', default: null, min: 0, max: 128 },
          { key: 'address', label: '地址', type: 'input', default: null, },
          { key: 'cydd', label: '采样地点', type: 'input', default: null, },
          { key: 'cyr', label: '采样人', type: 'input', default: null }
        ]
      },
      getDatas (list) {
        const params = this.getFormParams()
        return list.map(item => {
          let json = {}
          let data = { source: item.source || 'default' }
          params.forEach(v => {
            if (item[v.key] === undefined) {
              item[v.key] = null
            }

            if (item.group === 'json') {
              json[v.key] = item[v.key]
            } else {
              data[v.key] = item[v.key]
            }
          })
          data.jsonStr = JSON.stringify(json)
          return data
        })
      },
      checkUpdate (dataList) {
        return {
          list: dataList.filter(v => !v.jcsj || !v.ybid || !v.name),
          msg: '检测时间、样本编号、姓名不能为空，请检查'
        }
      },
      checkFormData (data) {
        if (!data.ybid) {
          throw new Error('样本编号不能为空')
        }
  
        if (!data.jcsj) {
          throw new Error('检测时间不能为空')
        }
  
        if (!data.name) {
          throw new Error('姓名不能为空')
        }
  
        if (!data.cydd) {
          throw new Error('采样地点不能为空')
        }
      },
      checkOrgName: false
    }
  }
}

// export const config = getConfig('hrms_thjkzx' || sysEnv.env)
export const config = getConfig(sysEnv.env)